export function saltarBeneficiarios(observations) {
  return observations && (observations.includes("PN04") || observations.includes("PN05"));
}

export function saltarDependientes(observations) {
  return (
    observations &&
    (observations.includes("PN01") ||
      observations.includes("PN06") ||
      observations.includes("PN07"))
  );
}

export function planType(observations) {
  if (observations.includes("PN01")) {
    return ["V"];
  }
  if (observations.includes("PN02")) {
    return ["V", "G"];
  }
  if (observations.includes("PN03")) {
    return ["V", "G", "D"];
  }
  if (observations.includes("PN04")) {
    return ["G"];
  }
  if (observations.includes("PN05")) {
    return ["G", "D"];
  }
  if (observations.includes("PN06")) {
    return ["V", "A"];
  }
  if (observations.includes("PN07")) {
    return ["A"];
  }
  if (observations.includes("PN08")) {
    return ["A", "G"];
  }

  return [];
}
