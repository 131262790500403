export const TIPOS_IDENTIFICACIONES = [
  { value: "C", text: "Cédula de Identidad" },
  { value: "O", text: "Cédula Residencia" },
  { value: "ENR", text: "Extranjero No Residente" },
  { value: "DIMEX", text: "Documento Único de Identificacion para extranjeros" },
  { value: "DIDI", text: "Documento de Identificación para Diplomáticos" },
];

export const TIPOS_DEPENDIENTES = [
  { value: "C", text: "Conyuge" },
  { value: "H", text: "Hijo" },
];

export const TIPOS_BENIFICARIOS = [
  { value: "ESPOSA(O)", text: "Conyuge" },
  { value: "HIJA(O)", text: "Hijo" },
  { value: "HERMANA(O)", text: "Hermano" },
  { value: "MADRE", text: "Madre" },
  { value: "PADRE", text: "Padre" },
  { value: "OTROS", text: "Otros" },
];

export const TIPOS_SINO = [
  { value: "S", text: "Si" },
  { value: "N", text: "No" },
];

export const TIPOS_SALARIO = [
  { value: "M", text: "Mensual" },
  { value: "S", text: "Semanal" },
  { value: "Q", text: "Quincenal" },
];

export const TIPOS_ESTADOCIVIL = [
  {
    value: "Soltero",
    text: "Soltera/o",
  },
  {
    value: "Casado",
    text: "Casada/o",
  },
  {
    value: "Divorciado",
    text: "Divorciada/o",
  },
  {
    value: "Otro",
    text: "Otro",
  },
];

export const TIPOS_SEXO = [
  {
    value: "M",
    text: "Masculino",
  },
  {
    value: "F",
    text: "Femenino",
  },
];

export const TIPOS_CUENTASBANCARIAS = [
  { value: "CORRIENTE", text: "Cuenta Corriente" },
  { value: "AHORRO", text: "Cuenta de Ahorros" },
];
