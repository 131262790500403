<template>
  <div>
    <b-container>
      <div>
        <b-modal v-model="modalShow">{{ messagge }}</b-modal>
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(next)" autocomplete="off">
          <b-card>
            <template #header>
              <h6 class="mb-0">Datos para ser completados por el Empleado:</h6>
            </template>
            <b-row>
              <!-- FIRST LINE -->
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="lastName"
                  label="Primer Apellido"
                  rules="required"
                  disabled
                />
              </b-col>
              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="secondLastName"
                  label="Segundo Apellido"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  name="firstName"
                  label="Primer Nombre"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="3" cols="12">
                <TextComponent :form="form" name="secondFirstName" label="Segundo Nombre" />
              </b-col>

              <!-- SECOND LINE -->

              <b-col md="3" cols="12">
                <TextComponent
                  :form="form"
                  v-model="form.birthDay"
                  name="birthDay"
                  label="Fecha de Nacimiento"
                  rules="required"
                  disabled
                />
              </b-col>

              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="nationality"
                  label="Nacionalidad"
                  rules="required"
                />
              </b-col>

              <b-col md="3" cols="12">
                <RadioComponent
                  :form="form"
                  name="sex"
                  label="Sexo"
                  rules="required"
                  disabled
                  :options="[
                    {
                      value: 'M',
                      text: 'Masculino',
                    },
                    {
                      value: 'F',
                      text: 'Femenino',
                    },
                  ]"
                />
              </b-col>

              <!-- THIRD LINE -->
              <!-- C = CI; O= CR,ENER,DIMEX,DIDI -->
              <b-col md="6" cols="12">
                <TextComponent
                  :form="form"
                  name="identification"
                  label="Número de Identificación"
                  rules="required"
                  disabled
                />
              </b-col>

              <!-- FOURTH LINE -->

              <b-col md="12" cols="12">
                <TextComponent :form="form" name="adress" label="Dirección" rules="required" />
              </b-col>

              <!-- FIFTH LINE -->
              <b-col md="4" cols="12">
                <TextComponent :form="form" name="apart" label="Apartado" />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent :form="form" name="phone" label="Teléfono" rules="required" />
              </b-col>

              <b-col md="4" cols="12">
                <TextComponent :form="form" name="phoneOffice" label="Teléfono Oficina" />
              </b-col>

              <!-- 6 LINE (CUENTA DE AHORRO Y CORRIENTE) -->
              <b-col md="4" cols="12" v-if="form.planMultiple && form.planMultiple.includes('V')">
                <IBANComponent
                  :form="form"
                  type="number"
                  prepend="PN"
                  name="bankAcountCc"
                  label="Nro Cta Corriente"
                  rules="required|min:17|max:17|Digit"
                  placeholder="Solo se deben ingresen dígitos"
                />
              </b-col>
              <b-col md="4" cols="12" v-if="form.planMultiple && form.planMultiple.includes('V')">
                <IBANComponent
                  :form="form"
                  type="number"
                  prepend="PN"
                  name="bankAcount"
                  label="Nro Caja de ahorro"
                  rules="required|min:17|max:17|Digit"
                  placeholder="Solo se deben ingresen dígitos"
                />
              </b-col>

              <b-col md="4" cols="12" v-if="form.planMultiple && form.planMultiple.includes('V')">
                <TextComponent :form="form" name="bank" label="Bank" rules="required" />
              </b-col>

              <!-- 7 LINE -->

              <b-col md="12" cols="12">
                <TextComponent
                  :form="form"
                  name="email"
                  label="Correo Electrónico"
                  rules="required"
                  type="email"
                  disabled
                />
              </b-col>
            </b-row>

            <!-- ENVIO -->
            <b-row align-h="end" class="mt-4">
              <b-col md="4" cols="4" style="display: flex; justify-content: flex-end">
                <b-button type="submit" variant="primary">Siguiente</b-button>
              </b-col>
            </b-row>
          </b-card>
        </b-form>
      </validation-observer>
    </b-container>
  </div>
</template>

<script>
import RadioComponent from "@/components/controls/RadioComponent.vue";
import TextComponent from "@/components/controls/TextComponent.vue";
import IBANComponent from "../../../components/controls/IBANComponent.vue";
import { planType, saltarBeneficiarios } from "../../../helpers/reglasPanama";

import { TIPOS_IDENTIFICACIONES } from "../../../data/panama/Selects";

export default {
  name: "Home",
  components: {
    TextComponent,
    RadioComponent,
    IBANComponent,
  },
  data: function () {
    return {
      messagge: "",
      control: {
        LanguageDisabled: 1,
        IdentificationsType: TIPOS_IDENTIFICACIONES,
      },
      form: {},
      modalShow: false,
      sessionId: "",
      shouldDisable: true,
    };
  },
  mounted: function () {
    this.$store.dispatch("app/loading", true);

    const { form } = this.$store.state.wizard;
    form.birthDay = this.formatDate(form.birthDay);

    if (form === undefined || form === null || form === "") {
      this.$router.push("/form/panama/");
      return;
    }

    this.form = form;
    this.form.planMultiple = planType(this.form.observations);

    // Ajustes para moneda
    this.form.moneyDisabled = this.form.money === "C";

    this.$store.dispatch("app/loading", false);
  },
  methods: {
    formatDate(date) {
      // Si la longitud de la fecha es 8 en formato yyyymmdd
      if (date.length === 8) {
        const year = date.substring(0, 4);
        const month = date.substring(4, 6);
        const day = date.substring(6, 8);
        return `${day}/${month}/${year}`;
      } else if (date.includes("/")) {
        // Si contiene "/"  está en el formato mm/dd/yyyy
        const parts = date.split("/");
        const month = parts[0].padStart(2, "0");
        const day = parts[1].padStart(2, "0");
        const year = parts[2];
        return `${day}/${month}/${year}`;
      } else {
        // Si no se puede determinar el formato, devolver la fecha original
        return date;
      }
    },
    next() {
      const form = { ...this.form };

      const saltar_beneficiarios = saltarBeneficiarios(this.form.observations);

      if (saltar_beneficiarios === true) {
        this.$store
          .dispatch("wizard/save", { index: 2, form })
          .then(this.$router.push("/form/panama-3.html"));
      } else {
        this.$store
          .dispatch("wizard/save", { index: 1, form })
          .then(this.$router.push("/form/panama-2.html"));
      }
    },
  },
};
</script>

<style scoped>
img.preview {
  width: 500px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.photo-preview {
  width: 150px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.fz16 {
  font-size: 16px;
}
.w100 {
  width: 100%;
}
.fr {
  float: right;
}
.furnituresContainer {
  border-bottom: 1px solid #ced4da;
}
.container {
  max-width: 1600px;
}
#input-BirthDay {
  margin-left: 3px;
}
.col-5 {
  background-color: red;
}
</style>
