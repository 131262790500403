export const TIPOS_IDENTIFICACIONES = [
  { value: "C", text: "Cédula de Identidad" },
  { value: "O", text: "Cédula Residencia" },
  { value: "ENR", text: "Extranjero No Residente" },
  { value: "DIMEX", text: "Documento Único de Identificacion para extranjeros" },
  { value: "DIDI", text: "Documento de Identificación para Diplomáticos" },
];

export const TIPOS_DEPENDIENTES = [
  { value: "C", text: "Esposo(a)" },
  { value: "H", text: "Hijo(a)" },
];

export const TIPOS_RELACION = [
  { value: "ESPOSA(O)", text: "Conyuge" },
  { value: "HIJA(O)", text: "Hijo" },
  { value: "HERMANA(O)", text: "Hermano" },
  { value: "MADRE", text: "Madre" },
  { value: "PADRE", text: "Padre" },
  { value: "OTROS", text: "Otros" },
];

export const TIPOS_SINO = [
  { value: "S", text: "Si" },
  { value: "N", text: "No" },
];
